$(function() {

	$document = $(document);

	$(".navToggle").magnificPopup({
		type: 'inline',
		fixedContentPos: false,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: false,
		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in'
	});


	$(".inlineFormContainer").each(function() {

		submitForm($(this).find("form"), $(this));

		$(this).find("form").validate();

	});

	$('.gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			titleSrc: function(item) {

				if (item.el[0].hasAttribute('title')) {
					return item.el.attr('title');
				} else {
					return "";
				}

			}
		}
	});

});


function submitForm($form, $formParent) {

    if ($form.length) {

    	var $button = $form.find("button");
    	var formHandle = $form.data("form-handle");
    	var freeform = $form[0].freeform;
		var newsletterChecked = false;

		freeform.setOption('ajax', true);

		freeform.addOnSubmitCallback(function(formElement, options) {

    		$formParent.addClass("submitted");
    		$button.addClass("spinner").text("Submitting...").prop("disabled", true);

			if ($form.find("input[type='checkbox']").first().is(":checked")) {
				newsletterChecked = true;
			}

    		return true;

    	});

    	freeform.addOnFailedAjaxSubmit(function(event, form, response) {

			$([document.documentElement, document.body]).animate({
				scrollTop: $formParent.offset().top
			}, 750);

			$formParent.removeClass("submitted");
    		$button.removeClass("spinner").text("Submit").prop("disabled", false);

    	});


    	freeform.setOption('renderSuccess', function() {

			$form.hide();

			$formParent.addClass("complete");

			$formParent.find(".thanksMessage").show();

			switch(formHandle) {
				case "newsletterForm":

					console.log("newsletterForm");

					dataLayer.push({
						'event': 'newsletterForm'
					});

					break;

				case "surveyForm":

					console.log("surveyForm");

					dataLayer.push({
						'event': 'surveyForm'
					});

					setTimeout(function() {
						window.location.replace("/thank-you");
					}, 100);


					break;


				case "contactform":
				case "contactwNewsletterOption":

					if (newsletterChecked) {

						dataLayer.push({
							'event': 'contactFormNewsletter'
						});

						console.log("contactForm /w Newsletter");

					} else {

						dataLayer.push({
							'event': 'contactForm'
						});

						console.log("contactForm");

					}

					break;
			}
    	});
    }
}
